<template>
  <v-tabs class="s-tabs" v-bind="$attrs">
    <slot />
  </v-tabs>
</template>

<script>
export default {
  name: 'STabs',
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.s-tabs {
  position: relative;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: rgba(0, 0, 0, 0.08);
    position: absolute;
    bottom: 0;
  }

  .v-tabs-bar {
    background: transparent !important;
  }

  .v-tab {
    font-weight: $font-medium;
    font-size: $text-md !important;
    text-transform: capitalize !important;
    color: var(--v-gray-base) !important;
    padding: 0 !important;
    margin-right: 20px !important;
    min-width: auto;
    letter-spacing: 0;
    &::before {
      background: none !important;
    }
    &:not(.v-tab--active):hover {
      color: rgba(0, 0, 0, 0.75) !important;
    }
    .v-ripple__container {
      opacity: none !important;
    }
  }

  .v-tab--active {
    color: var(--v-dark-base) !important;

    @media #{$smalldown} {
      color: var(--v-primary-base) !important;
    }

    &.v-tab:focus::before {
      opacity: 0 !important;
    }
  }
}
</style>
